import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "../../../../../environments/environment";
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { SlaCategoryDTO, SlaCategoryFormDTO } from "../dto/sla-category.dto"; // Adjusted import path and naming

@Injectable({
  providedIn: 'root'
})
export class SlaCategoryService {

  private apiUrl = environment.apiUrl; // Use the apiUrl from environment

  constructor(private http: HttpClient) {}

  getSlaCategories(
      sortField: string = 'title_en',
      sortOrder: string = 'asc',
      searchQuery?: string
  ): Observable<SlaCategoryDTO[]> {
      let params = new HttpParams()
      .set('sort_by', sortField)
      .set('sort_order', sortOrder);

      if (searchQuery) {
          params = params.set('keyword', searchQuery);
      }

      return this.http.get<SlaCategoryDTO[]>(this.apiUrl + '/sla-categories', {
          params,
      });
  }

  getSlaCategory(id: number): Observable<SlaCategoryDTO> {
      return this.http.get<SlaCategoryDTO>(`${this.apiUrl}/sla-categories/${id}`);
  }

  addSlaCategory(
      slaCategory: SlaCategoryFormDTO
  ): Observable<SlaCategoryFormDTO> {
      return this.http.post<SlaCategoryFormDTO>(
      this.apiUrl + '/sla-categories',
      slaCategory
      );
  }
  
    updateSlaCategory(
      id: number,
      slaCategory: SlaCategoryFormDTO
    ): Observable<SlaCategoryFormDTO> {
      return this.http.put<SlaCategoryFormDTO>(
        `${this.apiUrl}/sla-categories/${id}`,
        slaCategory
      );
    }
  
    activateSlaCategories(ids: number[]): Observable<any> {
      return this.http.put(`${this.apiUrl}/sla-categories/activate`, { ids: ids });
    }
  
    deactivateSlaCategories(ids: number[]): Observable<any> {
      return this.http.put(`${this.apiUrl}/sla-categories/deactivate`, { ids: ids });
    }
  
    deleteSlaCategories(ids: number[]): Observable<any> {
      return this.http.post(`${this.apiUrl}/sla-categories/delete`, { ids: ids });
    }
}
